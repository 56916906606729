import { mapGetters } from 'vuex';

export default {
  inject: ['translationService', 'accountService'],
  components: {},
  data() {
    return {
      /**
       * Options for About modal dialog including flag to hide it.
       */
      aboutDialogOptions: {
        text: this.$t('label.no_version_info'),
        show: false,
      },
      showMenu: false,
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters([
      'authenticated',
      'getAuthorities',
      'isCA',
      'account',
      'currentLanguage',
      'languages',
      'fullVersion',
      'versionNote',
      'progress',
      'sysInfo',
    ]),

    /**
     * NOTE: the menu items is reactive because it might rely on some reactive data.
     */
    items() {
      return [
        {
          title: this.$t('menu.item.system_settings'),
          link: '/admin/system',
          authorization: ['SA'],
          icon: 'fa-cogs',
        },
        {
          title: this.$t('menu.item.user_list'),
          link: '/admin/users',
          authorization: ['SA'],
          icon: 'fa-users',
        },
        {
          title: this.$t('menu.item.company_mng'),
          link: '/admin/companies',
          authorization: ['CA'],
          icon: 'fa-building',
        },

        {
          // only CA has separator because of extra actions
          separator: true,
          // authorization: ['CA'],
        },

        {
          title: this.$t('menu.item.edit_profile'),
          link: '/admin/profile',
          icon: 'fa-user',
        },
        {
          separator: true,
        },
        {
          title: this.$t('menu.item.about'),
          action: { name: 'showAboutModal' },
          icon: 'fa-info',
        },
        {
          separator: true,
        },
        {
          title: this.$t('menu.item.logout'),
          action: { name: 'logout' },
          icon: 'fa-sign-out-alt',
        },
      ];
    },
  },
  methods: {
    logout() {
      this.accountService().doLogout();
      // this.logoutUser();
      // this.$router.push('/login');
    },

    showAboutModal() {
      this.aboutDialogOptions.show = true;
    },

    /**
     * Performs action according to configuration: some router action or router link.
     * @param {object} item - The menu item.
     */
    doAction(item) {
      // in case of link, go to router view
      if (item.link) {
        this.$router.push(item.link).catch((e) => {
          console.log('Navigation for menu item: ', e.message);
        });
      } else {
        // execute action otherwise
        this[item.action.name](item.action.payload);
      }
    },

    /**
     * Evaluate should menu item be displayed or not according to authority.
     * @param {object} item - The menu item iterated in loop.
     * @returns {boolean} True if the item should be shown.
     */
    showMenuItem(item) {
      const authorization =
        !item.authorization ||
        (item.authorization && item.authorization.some((auth) => this.getAuthorities.indexOf(auth) >= 0));
      /* The alternative solution with jhipster:
          this.$store.getters['account'] &&
          item.authorization.some((auth) => this.$store.getters['account.authorities'].indexOf(auth) >= 0));
*/

      return authorization;
    },
    /**
     * Evaluates the translation key for a back button based on meta data in the route.
     * If not found, returns the default.
     */
    backButtonKey() {
      if (this.$route.meta && this.$route.meta.backButtonKey) {
        return this.$route.meta.backButtonKey;
      }
      return 'btn.back';
    },
  },
};

import VueI18n from 'vue-i18n';
// not needed yet
// import JhiFormatter from './formatter';
import { setupAxiosInterceptors } from './axios-interceptor';

// not needed yet
// import VueCookie from 'vue-cookie';
// import Vue2Filters from 'vue2-filters';

// import * as filters from '@/shared/date/filters';

/**
 * Initializes vue before creating the app.
 * @param {object} vue - The Vue object that should be configured before bootstrapping the app instance.
 * @param {object} services - The references to service instances that can be needed.
 */
export function initVueApp(vue, services) {
  // not needed yet
  // vue.use(VueCookie);
  // vue.use(Vue2Filters);
  setupAxiosInterceptors(services);
  // DSPR CUSTOM: the custom warning handler introduced to reduce vuetify components issues.
  vue.config.warnHandler = (err /* , vm, info */) => {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    // console.warn('Custom vue warn handler: ', err, vm.name, info);
    console.warn('Warn reported in g-handler: ', err);
  };
  // DSPR CUSTOM: the custom error handler, that will catch errors not handled in components and in axios.
  // NOTE: there are additional params in handler function, check Vue API if needed.
  vue.config.errorHandler = (error) => {
    // NOTE: - suggestion is to use a common code for both error handler functions
    // extract the sent error details (optional)
    const { message, errorDetails } = error;

    // Display the message in UI only if in appropriate format
    if (message.startsWith('error.') || message.startsWith('message.')) {
      console.info('Alert handled by g-handler.');
      services.alertService.showAlert(message);
    } else {
      // reporting not handled error as warning (for now)
      console.warn('Error reported in g-handler', error, errorDetails);
    }

    // if the error was not found, go back to from route if available
    if (message.startsWith('error.entity_not_found')) {
      services.accountService.goBackOnNotFound();
    }
  };
  // in addition to error handler, initialize the browser unhandled rejection
  window.addEventListener('unhandledrejection', function (event) {
    const { reason } = event;
    const { message } = reason;

    if (message && (message.startsWith('error.') || message.startsWith('message.'))) {
      console.info('Alert handled by w-ev-listener.');
      services.alertService.showAlert(message);
      event.preventDefault();
    } else {
      // reporting not handled error as warning (for now)
      console.error('Error reported in w-ev-listener', message);
      // Prevent the default handling (such as outputting the
      // error to the console if not recognized as app logic error message?)
      // event.preventDefault();
    }

    // if the error was not found, go back to from route if available
    if (message.startsWith('error.entity_not_found')) {
      services.accountService.goBackOnNotFound();
    }
  });

  // filters can be moved later here
  // filters.initFilters();
}

/**
 *
 * @param vue
 */
export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    silentTranslationWarn: true,
  });
}

// NOTE: The app store could be initialized here instead of init from separate file
/*
export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    state: {},
    mutations: {},
    getters: {},
  });
}
*/

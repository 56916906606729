/**
 * Common constants across client code.
 *
 * NOTE: some of constants are dynamically built based on environment. Right now this is the common solution for such
 * configuration options - in order to have not so many configuration files.
 *
 * This dynamic build DOES NOT requires any modification of this file, this is done during build process.
 */

// Detect whether the API url is specified from non-web client like electron app
const apiUrl = new URLSearchParams(window.location.search).get('apiUrl');

export default {
  /**
   * The authentication token name used as a key for storing auth token in browser storage.
   * @type {string}
   */
  AUTH_TOKEN_NAME: 'avoucher-authToken',

  /**
   * The date only format used for API calls.
   */
  DATE_FORMAT_API: 'YYYY-MM-DD',

  /**
   * The time only format used for API calls.
   */
  TIME_FORMAT_API: 'HH:mm',

  /**
   * The URL to server from client's application. By default, for production the client is on the same server.
   * However, with webpack/vue-cli the client is not always on the same server, that's why the url must be specified.
   */
  SERVER_URL: apiUrl || (process.env.NODE_ENV === 'production' ? '/' : process.env.VUE_APP_SERVER_DEV_URL),

  /**
   * The list of supported languages with translations (JHI pattern).
   */
  SUPPORTED_LANGUAGES: JSON.parse(process.env.VUE_APP_SUPPORTED_LANGUAGES),

  /**
   * The captcha pub key.
   */
  RECAPTCHA_PUBKEY: process.env.VUE_APP_RECAPTCHA_PUBKEY,
};

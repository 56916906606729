/**
 * The Mixin with security and identification support methods.
 */
const securityMixin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        hasAnyAuthority(authoritiesParam) {
          let authorities = authoritiesParam;
          if (typeof authoritiesParam === 'string') {
            authorities = [authoritiesParam];
          }
          if (!this.$store.getters.authenticated || !this.$store.getters.getAuthorities) {
            return false;
          }

          for (let i = 0; i < authorities.length; i += 1) {
            if (this.$store.getters.getAuthorities.includes(authorities[i])) {
              return true;
            }
          }

          return false;
        },
        /**
         * Evaluates that user (implements user interface) has any of specified authorities.
         * @param {object} user - The user item that implements user interface (has authorities field).
         * @param {string|Array<string>} authoritiesParam - The authorities param to check, string or arrays of them.
         * @returns {boolean} - If has authorities, return true.
         */
        userHasAuthority(user, authoritiesParam = []) {
          let authorities = authoritiesParam;
          if (typeof authoritiesParam === 'string') {
            authorities = [authoritiesParam];
          }
          if (user && user.authorities && user.authorities.length) {
            for (let i = 0; i < authorities.length; i += 1) {
              if (user.authorities.includes(authorities[i])) {
                return true;
              }
            }
            return false;
          }
          return false;
        },
      },
    });
  },
};

export default securityMixin;

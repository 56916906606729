import axios from 'axios';
import constants from '../common/constants';

const { SERVER_URL } = constants;

/**
 * The client service to support company management
 */
export default class CompanyInfoService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
    // this.init();
  }

  /**
   * Retrieves company info that is available to all, like full name, some info.
   * @param {string} slugName - The slug name.
   * @returns {Promise<object>} - The promise with object of retrieved company.
   */
  async retrieveCompanyPublicInfo(slugName) {
    const res = await axios.get(`api/c-info/${slugName}`);
    return res.data;
  }

  /**
   * Evaluates SERVER URL for CI resource for company.
   * This is served under API routes.
   *
   * @param customId
   * @param ciResType
   */
  getCompanyCIResURL(customId, ciResType) {
    return `${SERVER_URL}api/c-cires/${customId}/${ciResType}`;
  }
}

import axios from 'axios';

/**
 * The service for handling remote packs through avoucher app as a proxy.
 */
export default class RemotePackService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
  }

  /**
   * Retrieves remote packs.
   * @returns {Promise<Array<object>>} - The array of remote packs.
   */
  async retrievePacks() {
    const res = await axios.get(`api/remote-packs`);
    return res.data;
  }

  async activatePack(frmDTO) {
    const res = await axios.post(`api/remote-pack-activate`, frmDTO);
    return res.data;
  }
}

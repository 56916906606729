import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{class:_vm.appCssClass,style:(_vm.appCssStyle)},[(_vm.isAdminRoute)?[_c('app-alerts'),_c('app-header'),_c(VContent,[_c(VContainer,[_c(VLayout,[_c(VFlex,[(_vm.currentLanguage)?_c('router-view'):_vm._e()],1)],1)],1)],1)]:[_c(VContent,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[(_vm.currentLanguage)?_c('router-view'):_vm._e()],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
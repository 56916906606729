import axios from 'axios';
import constants from '../common/constants';

const { AUTH_TOKEN_NAME } = constants;

/**
 * The home paths based on user's authority.
 */
const HOME_ROUTES = Object.freeze({
  SA: '/admin/users',
  CA: '/admin/companies',
});

/**
 * The array of not available pages when already signed in.
 * @type {string[]}
 */
const NOT_AVAILABLE_PAGES_WHEN_SIGNED_IN = Object.freeze(['loginPage']);

export default class AccountService {
  constructor({ store, router, alertService = undefined, translationService = undefined }) {
    this.store = store;
    this.translationService = translationService;
    this.alertService = alertService;
    this.router = router;
    /**
     * The from route is used to remember the vue router 'from' route, since we have a router configured in this class.
     * @type {object | null}
     */
    this.fromRoute = null;

    console.log('account service instance created');
  }

  /**
   * Trigger initialization of account service asynchronously.
   */
  async init() {
    // NOTE: DSPR CUSTOM: Right now the app is using browser session store for storing authorization.
    const token = sessionStorage.getItem(AUTH_TOKEN_NAME);
    if (!this.store.getters.account && !this.store.getters.logon && token) {
      // IMPORTANT:
      // if retrieve account fails during initialization, handle this error exclusively without the use of error handler
      try {
        await this.retrieveAccount();
      } catch (e) {
        // translations needed if not loaded, not present at all in the client app
        await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
        // show alert with some delay
        setTimeout(() => {
          this.alertService.showAlert(e.message);
        }, 500);
        // perform logout logic
        this.doLogout();
      }
    } else {
      // initialize translations if account is not logged in
      await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
    }
    // router after all account data are available
    this.initRouter();
  }

  /**
   * Initializes router hooks and security. The method should bee used after account is retrieved.
   */
  initRouter() {
    this.router.beforeEach((to, from, next) => {
      // NOTE: used in system mixin, do not delete!
      this.fromRoute = from;

      // NOTE: if the requested route is / that is dynamic, evaluate it based on authority
      if (to.path === '/admin' || to.path === '/admin/') {
        next(this.homePageByAuth());
        return;
      }
      if (!to.matched.length) {
        // NOTE: we are using home page / login instead of not-found for now
        // next('/not-found');
        next(this.homePageByAuth());
        return;
      }

      // in case of already signed in user, prevent going to not allowed pages when signed in
      if (this.account && from.name !== to.name && NOT_AVAILABLE_PAGES_WHEN_SIGNED_IN.indexOf(to.name) >= 0) {
        // console.log(this.account, to, from);
        next(this.homePageByAuth());
        return;
      }

      if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
        if (!this.hasAnyAuthority(to.meta.authorities)) {
          sessionStorage.setItem('requested-url', to.fullPath);
          console.log('ROUTER: BEFORE EACH - FORBIDDEN path', to.fullPath);
          // NOTE: we are using login instead of forbidden for now
          // next('/forbidden');
          next(this.homePageByAuth());
        } else {
          // there is an authority, so proceed
          next();
        }
      } else {
        // no authorities, so just proceed
        next();
      }
    });
  }

  /**
   * Evaluates home page by authority.
   */
  homePageByAuth() {
    // find first in the list and go there
    const userAuthorities = this.store.getters.getAuthorities;
    const userAuth = Object.keys(HOME_ROUTES).find((authority) => userAuthorities.indexOf(authority) >= 0);
    if (userAuth) {
      const route = HOME_ROUTES[userAuth];
      return route;
    }
    // NOTE: if not authenticated - the only available page is login
    return '/admin/login';
  }

  /**
   * Initiate the login for login page.
   * The JHI pattern logic.
   * NOTE: in case of some http error, the error needs to be catched on invoker page.
   * @param {object} dto - The login form DTO.
   * @returns {Promise<void>} - The promise.
   */
  async doLogin(dto) {
    const result = await axios.post('api/authenticate', dto);
    // NOTE: the bearer token is received over body but also within response header (JHI pattern that follows oauth specs?)
    const bearerToken = result.headers.authorization;
    // const bearerToken = result.data.token;
    // console.log('AUTHENTICATE: bearer token', bearerToken, result.headers);
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      // if (bearerToken) {
      const jwt = bearerToken.slice(7, bearerToken.length);

      // JHI pattern: remember the auth token in session storage as alternative to the session cookie
      sessionStorage.setItem(AUTH_TOKEN_NAME, jwt);
    }
    // try to load account data
    await this.retrieveAccount();
  }

  /**
   * Initiate the login for login page, with simplified logic, using session.
   * The JHI pattern logic.
   * NOTE: in case of some http error, the error needs to be catched on invoker page.
   * @param {object} dto - The login form DTO.
   * @returns {Promise<void>} - The promise.
   */
  async doLoginWithSession(dto) {
    const result = await axios.post('api/authenticate', dto);

    // if there is a user in response, then ok, try to get the complete account
    if (result.data && result.data.user) {
      // try to load account data
      await this.retrieveAccount();
    }
  }

  doLogout() {
    // JHI pattern: as future alternative to the cookie, delete the auth token from (local and) session store
    sessionStorage.removeItem(AUTH_TOKEN_NAME);
    // clear requested url
    sessionStorage.removeItem('requested-url');
    this.store.commit('logout');
    // this.router.push('/');
    this.router.replace({ name: 'loginPage' });
  }

  /**
   * Redirects to fromRoute in case of entity not found error (check error handler for details).
   */
  goBackOnNotFound() {
    if (this.fromRoute) {
      this.router.replace({ ...this.fromRoute });
    }
  }

  /**
   * Retrieves account full info from the server and initializes corresponding store data and other resources.
   */
  async retrieveAccount() {
    this.store.commit('authenticate');
    const response = await axios.get('api/account');
    const account = response.data;
    if (account) {
      this.store.commit('authenticated', account);

      if (this.store.getters.currentLanguage !== account.langKey) {
        this.store.commit('currentLanguage', account.langKey);
      }
      // if some URL was requested, try to use it
      // if (sessionStorage.getItem('requested-url')) {
      //   this.router.replace(sessionStorage.getItem('requested-url'));
      //   sessionStorage.removeItem('requested-url');
      // } else if (this.router.currentRoute.path === '/login') {
      //   // otherwise resolve home page by authorities - if we are coming from login
      //   this.router.push(this.homePageByAuth());
      // }
    } else {
      // sessionStorage.removeItem('requested-url');
      this.doLogout();
    }
    await this.translationService.refreshTranslation(this.store.getters.currentLanguage);
  }

  /**
   * Returns true if user has any of specified authorities.
   * NOTE: this method is actually not used, instead the mixin is available for use on pages.
   * @param {string|Array<string>} authoritiesParam - The authority or array of authorities names.
   * @returns {boolean} - True of has any of specified authorities.
   */
  hasAnyAuthority(authoritiesParam) {
    let authorities = authoritiesParam;
    if (typeof authorities === 'string') {
      authorities = [authoritiesParam];
    }
    if (!this.authenticated || !this.userAuthorities) {
      return false;
    }

    for (let i = 0; i < authorities.length; i += 1) {
      if (this.userAuthorities.includes(authorities[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Retrieves ONLY account's profile data for editing.
   * @returns {Promise<object>} - The promise with profile DTO.
   */
  async retrieveProfile() {
    const res = await axios.get('api/account/profile');
    return res.data;
  }

  /**
   * Retrieves ONLY account's profile data for editing.
   * @param {object} formDTO - The DTO with all profile form data.
   * @returns {Promise<object>} - The promise with profile DTO.
   */
  async saveProfile(formDTO) {
    const dto = { ...formDTO };
    const res = await axios.put('api/account/profile', dto);
    // if new token is returned (token is changed because of modification of some token related fields) store a new token in appropriate place
    if (res.data.newTkn) {
      sessionStorage.setItem(AUTH_TOKEN_NAME, res.data.newTkn);
    }
    // after updating, refresh the account in the store
    await this.retrieveAccount();
    return res.data;
  }

  get authenticated() {
    return this.store.getters.authenticated;
  }

  get userAuthorities() {
    return this.store.getters.account.authorities;
  }

  get account() {
    return this.store.getters.account;
  }

  get authToken() {
    return sessionStorage.getItem(AUTH_TOKEN_NAME);
  }
}

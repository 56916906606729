import dayjs from 'dayjs';

import { mapGetters } from 'vuex';

/**
 * Helper method to prepare properly the dayjs instance out of provided date.
 * NOTE: This function is dealing currently ONLY with Date, not designed for parsing a date from tz.
 * @param {Date} date - The date which will be used for creating an instance of dayjs.
 * @param {string} locale - The locale supported by app.
 * @param {string?} tz - The timezone in standard format supported by dayjs, e.g. Europe/London, Europe/Zurich.
 * If tz is not specified, the local timezone will be guesses by dayjs.
 * @returns {dayjs.Dayjs} - The prepared instance of dayjs according to specified params.
 */
function toLocalizedTZ(date, locale, tz = undefined) {
  return tz ? dayjs(date).locale(locale).tz(tz) : dayjs(date).locale(locale);
}

/**
 * The formatting support mixin
 * @module mixins/sortMixin
 */
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {};
      },
      computed: {
        ...mapGetters(['sysInfo', 'currentLanguage']),
      },
      methods: {
        /**
         * Formats date/time for technical display.
         * NOTE: right now hardcoded format for app.
         * @function
         * @param {Date} value - The date to format.
         */
        fmtDateTime(value) {
          // return toLocalizedTZ(value, this.$store.getters.currentLanguage).format('L, LT');
          return toLocalizedTZ(value, this.$store.getters.currentLanguage).format('DD.MM.YYYY, HH:mm');
        },
        /**
         * Formats date only for technical display.
         * NOTE: right now hardcoded format for app.
         * @function
         * @param {Date} value - The date to format.
         */
        fmtDate(value) {
          if (!value) {
            return '';
          }
          // return toLocalizedTZ(value, this.$store.getters.currentLanguage).format('L, LT');
          return toLocalizedTZ(value, this.$store.getters.currentLanguage).format('DD.MM.YYYY');
        },

        /**
         * Creates the CSV out of items by specified item property.
         * @param {Array<object>} items - The items.
         * @param {string} property - The property to extract from the item.
         */
        itemsToCsv(items, property) {
          return items.map((item) => item[property]).join(', ');
        },

        /**
         * The simple, quick helper used to minimize html for UI and small management purposes on client side.
         * Taken from yairEO/minifyHTML.js gist.
         * @param {string} s - The html string.
         * @returns {string} - The minimized html.
         */
        minifyHtml(s) {
          return s
            .replace(/>[\r\n ]+</g, '><')
            .replace(/(<.*?>)|\s+/g, (m, $1) => $1 || ' ')
            .trim();
        },

        /**
         * Draft support function for converting new lines in text to html breaks.
         * NOTE: currently br is used.
         * @param {string} text - The text to modify.
         * @returns {string} - The modified text.
         */
        textToHtmlBreaks(text) {
          return text
            .split(/\r\n|\r|\n/)
            .map((line) => `${line}<br/>`)
            .join('');
        },

        /**
         * Support function to convert the case.
         * @param {string} str - The string to convert.
         * @returns {string} - The snake case string.
         */
        camelToSnakeCase(str) {
          return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
        },
      },
    });
  },
};

/**
 * The main store module.
 * @namespace store
 */
import Vue from 'vue';
import Vuex from 'vuex';
import commonConfig from '../common/constants';

Vue.use(Vuex);

const MIN_PASS_PENGTH = 6;

const store = new Vuex.Store({
  state: {
    /**
     * The router route initially requested by user (not full url yet)
     * NOTE: Browser storage used for this, so here not used.
     */
    // requestedUrl: null,

    // JHI:
    logon: false,
    userIdentity: null,
    authenticated: false,

    passMinLength: MIN_PASS_PENGTH,

    // ////////////////////////////////////////
    // NEW Alert in store, for all new services
    // ////////////////////////////////////////
    // alertType: '',
    /**
     * The alert message must be an object with some message text inside in order to be detectable for
     * changes.
     * The changes of this object are influencing the show up of alert.
     */
    alertMessage: { msg: '' },

    /**
     * The new way of language selection from the top bar, like JHI pattern.
     * This field will be used on PUBLIC pages right now, after login, the user's profile fill be used.
     * Language in store is not initialized yet, browser detection delegated to translation.service.
     */
    currentLanguage: '',

    /**
     * The list of supported languages with translations (JHI pattern).
     */
    languages: {
      ...commonConfig.SUPPORTED_LANGUAGES,
      // NOTE: The following solution can be used to include optional languages but ONLY if the browser has them in preferred list
      // ...(window.navigator.languages.findIndex((lang) => lang.startsWith('sr')) >= 0
      //   ? { sr: { name: 'Srpski', timePickerFormat: '24hr' } }
      //   : {}),
    },

    /**
     * The minimum set of client needed system settings + information about the version - sysInfo.
     * NOTE: not all system settings are exposed to the client, but only the few of them - sysInfo.
     * In addition to sysInfo, one more property is available - versionInfo that contains a model of the last application
     * update.
     *
     * There is the list of app supported activities.
     */
    sysInfo: {},

    /**
     * The data used to track application progress bar.
     * Right now the implementation doesn't requires too much props because simple indeterminate progress is used.
     */
    progressBar: {
      loading: false,
    },
  },
  getters: {
    getAuthorities(state) {
      return state.userIdentity ? state.userIdentity.authorities || [] : [];
    },

    /**
     * Evaluates is the logged user Company Admin.
     * @param {object} state - The store state.
     * @param {object} getters - The store getters. NOTE: injected as second argument.
     * @returns {boolean} - Returns true if the logged user has CA authority.
     */
    isCA(state, getters) {
      return getters.getAuthorities.indexOf('CA') !== -1;
    },

    /**
     * The timepicker format is evaluated based on user's language.
     * @param {object} state - The store state.
     * @returns {string} - The format for vuetify timepicker: ampm, 24hr.
     */
    timePickerFormat: (state) => {
      return state.languages[state.currentLanguage] ? state.languages[state.currentLanguage].timePickerFormat : 'ampm';
    },

    // JHI
    currentLanguage: (state) => state.currentLanguage,
    languages: (state) => state.languages,
    logon: (state) => state.logon,
    account: (state) => state.userIdentity,
    authenticated: (state) => state.authenticated,

    // ////////////////////////////////////////
    // NEW Alert in store, for all new services
    // NOTE: the type of message (error, info) is determined based on message prefix: error or message
    // so the alert type is not needed
    // ////////////////////////////////////////
    // alertType: (state) => state.alertType,
    alertMessage: (state) => state.alertMessage,

    sysInfo(state) {
      return state.sysInfo;
    },

    /**
     * Formats application version for display purposes.
     * @param {object} state - The store state.
     * @returns {string} - The formatted version.
     */
    fullVersion(state) {
      const { versionInfo: vInfo } = state.sysInfo;
      if (vInfo) {
        return `v${vInfo.major}.${vInfo.minor}.${vInfo.patch}`;
      }
      return '';
    },

    /**
     * Formats last application update for display purposes.
     * @param {object} state - The store state.
     * @returns {string} - The formatted app update.
     */
    versionNote(state) {
      const { versionInfo: vInfo } = state.sysInfo;
      if (vInfo) {
        return `${vInfo.description}`;
      }
      return '';
    },

    /**
     * Getter for progress bar state.
     * @param {object} state - The store state.
     */
    progress(state) {
      return state.progressBar;
    },

    customToolbar(state) {
      return state.customToolbar;
    },

    passMinLength(state) {
      return state.passMinLength;
    },
  },
  mutations: {
    // JHI
    currentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
    },
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
    },

    // JHI
    // ////////////////////////////////////////
    // Alerts
    // ////////////////////////////////////////

    initAlert(state) {
      state.alertMessage = {};
    },
    setAlertMessage(state, alertMessage) {
      state.alertMessage = alertMessage;
    },

    // ////////////////////////////////////////
    // Progress bar
    // ////////////////////////////////////////

    initProgress(state) {
      state.progressBar = {
        loading: false,
      };
    },

    setProgress(state, loading) {
      state.progressBar.loading = loading;
    },

    // Custom sys info for all needed sys info
    sysInfo(state, sysInfo) {
      state.sysInfo = sysInfo;
    },
  },

  plugins: [],
});

export default store;

export default {
  // the solution to inject the function from app context
  inject: ['accountService'],
  components: {},
  data() {
    return {
      /**
       * Show password in input or not
       */
      showPassword: false,
      /**
       * The login form elements
       */
      user: {
        email: '',
        password: '',
      },
      value: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // detect email param forwarded from some other page like order confirmation
      const emailParam = this.$router.currentRoute.params.email;
      if (emailParam) {
        this.user.email = emailParam;
      }
    },
    async loginUser() {
      const result = await this.$validator.validateAll();

      if (result) {
        let recapToken;
        // trigger captcha only in configured in client app on build
        if (this.$recaptchaLoaded) {
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          recapToken = await this.$recaptcha('login');
        }

        // WITH auth header
        await this.accountService().doLogin({
          ...this.user,
          // ignored if no captcha configured
          recapToken,
        });

        // WITH session
        // await this.accountService().doLoginWithSession({
        //   ...this.user,
        // });
        // if there is requested url clear it and go there

        const requestedUrl = sessionStorage.getItem('requested-url');
        if (requestedUrl) {
          // clear session and go
          sessionStorage.removeItem('requested-url');
          this.$router.replace(requestedUrl);
        } else {
          this.$router.replace(this.accountService().homePageByAuth());
        }
      }
    },
  },
};

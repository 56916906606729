import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSnackbar,{ref:"appSnackbar",attrs:{"id":"app-alert","bottom":true,"color":_vm.msgType,"multi-line":true,"value":_vm.msgType,"timeout":0,"auto-height":""},on:{"click":function($event){$event.stopPropagation();return _vm.onAlertClick()}},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c(VLayout,{attrs:{"row":"","align-center":""}},[_c(VFlex,{staticClass:"app-snack-content subheading"},[_c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VFlex,{attrs:{"shrink":""}},[_vm._v(_vm._s(_vm.msgText))])],1)],1),_c(VBtn,{staticClass:"align-self-start ml-0",style:({ visibility: _vm.showClose ? 'visible' : 'hidden' }),attrs:{"color":"primary","icon":"","small":"","flat":"","bottom":"","left":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeAlert()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("fa-window-close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default class JhiAlertService {
  constructor(store) {
    this.store = store;
    // initialize alerts
    this.store.commit('initAlert');
    // initialize progress
    this.store.commit('initProgress');
  }

  /**
   * Shows the alert based on message key.
   * @param {string} msgKey - The message key that will be translated based on pre-loaded bundle on client.
   * If not translatable, it will be displayed as is ?
   * @param {object?} msgParams - The message parameters that can be used for parametrized translation keys.
   */
  showAlert(msgKey, msgParams = undefined) {
    this.store.commit('setAlertMessage', { msg: msgKey, msgParams });
  }

  /**
   * Clear the alert by resetting the one and only message.
   */
  clearAlert() {
    this.store.commit('setAlertMessage', { msg: '' });
  }

  /**
   * Show/hide progress bar.
   * @param {boolean} loading - The flag should the progress bar be displayed or not.
   */
  showProgress(loading = true) {
    this.store.commit('setProgress', loading);
  }
}

import { mapGetters } from 'vuex';

import appAlerts from './cmp/app-alerts.vue';
import appHeader from './cmp/app-header.vue';

const App = {
  inject: ['accountService', 'companyInfoService', 'translationService'],
  data() {
    return {};
  },
  /**
   * The application is providing some common functionality to all child components.
   */
  provide() {
    return {
      /**
       * Invokes changing of language through translation service.
       * @param {string} newLanguage - The new language key.
       * @returns {Promise<void>}
       */
      changeLanguage: async (newLanguage) => {
        await this.translationService().refreshTranslation(newLanguage);
      },
    };
  },
  components: {
    appAlerts,
    appHeader,
  },
  computed: {
    ...mapGetters(['currentLanguage', 'getCompanyId']),
    lang() {
      return this.currentLanguage;
    },
    companyCustomId() {
      return this.$route.params.slugName;
    },
    /**
     * The css class for top application element.
     */
    appCssClass() {
      if (this.isAdminRoute) {
        return 'app-container-admin';
      }
      return 'app-container';
    },
    /**
     * The css style for top application element.
     */
    appCssStyle() {
      if (this.isAdminRoute) {
        return {};
      }
      return {
        backgroundImage: `url('${this.ciResUrl('BACKGROUND')}')`,
        backgroundSize: 'cover',
      };
    },
  },
  created() {},
  mounted() {},
  watch: {
    /**
     * NOTE: This is the simplest solution to popup the updated language in the store into the URL.
     * The rest of the language detection change and selection logic is now in client's translation.service.js.
     * @param {string} newVal - The new language key.
     */
    lang(newVal) {
      // console.log('replacing route', newVal);
      this.$router.replace({
        query: {
          ...this.$router.currentRoute.query,
          langKey: newVal,
        },
      });
    },
  },
  methods: {
    ciResUrl(ciResType) {
      return this.companyInfoService().getCompanyCIResURL(this.companyCustomId, ciResType);
    },
  },
};
export default App;
